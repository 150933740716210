import {
  Card,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {ReactComponent as Success} from 'src/images/success.svg'
import {ReactComponent as Error} from 'src/images/error.svg'
import {ReactComponent as Info} from 'src/images/info.svg'
import {ReactComponent as Icon} from 'src/images/icon.svg'
import EditableCard, {EditableCardField} from '../custom/CuiEditableCard'
import {useMainStore} from 'src/contexts/Main'
import Institution from 'src/entities/Institution'
import {
  associationDetails,
  bankDetails,
  contactDetails,
  institutionDetails
} from './institutionsDetailsConfig'
import Association from 'src/entities/Association'
import Contact from '../../entities/Contact'
import {useEffect, useState} from 'react'

import Month from 'src/entities/Month'
import {defaultMonth} from 'src/utils/month'
import EditIcon from '@mui/icons-material/ModeEdit'
import EditableText from './InstitutionsNote'

const InstitutionsDetails = observer(() => {
  const {
    currentInstitution,
    updateInstitution,
    updateAssociation,
    updateNote,
    updateMatchTeamNote,
    updateContact,
    setBranches,
    relevantMonths,
    getInstitution
  } = useMainStore().institutionStore

  const [yeshivaGuyOffset, setYeshivaGuyOffset] = useState<any>()
  const [fullDayOffset, setFullDayOffset] = useState<any>()
  const [halfDayOffset, setHalfDayOffset] = useState<any>()
  const [month, setMonth] = useState<Month | undefined>(defaultMonth)
  const [isEditNoteMode, setIsEditNoteMode] = useState(false)
  const [isEditMatchTeamNote, setIsEditMatchTeamNote] = useState(false)

  const {getStudentsDashboard, dashboardByMonth} = useMainStore().studentsStore

  useEffect(() => {
    if (currentInstitution) {
      setYeshivaGuyOffset(
        currentInstitution.offset?.find(o => o.studentTypeId === 1)
      )
      setFullDayOffset(
        currentInstitution.offset?.find(o => o.studentTypeId === 2)
      )
      setHalfDayOffset(
        currentInstitution.offset?.find(o => o.studentTypeId === 3)
      )
      getStudentsDashboard(currentInstitution.id)
    }
  }, [currentInstitution, currentInstitution?.offset, getStudentsDashboard])

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      if (currentInstitution?.id) {
        try {
          if (isMounted) {
            await getInstitution(currentInstitution.id.toString())
          }
        } catch (error) {
          if (isMounted) {
            console.error('Error fetching institution data:', error)
          }
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [currentInstitution?.id, getInstitution])

  const onCancelBank = () => {
    setBranches(currentInstitution?.association?.bankId)
  }

  const handleChangeMonth = (event: any) => {
    const selectedMonth = JSON.parse(event.target.value) as Month
    setMonth(selectedMonth)
  }

  const dashboard = month && dashboardByMonth?.[month?.year][month.id]

  return (
    <>
      {currentInstitution && (
        <Stack
          sx={{useFlexGap: 'true', flexWrap: 'wrap'}}
          direction="row"
          width={'100%'}
        >
          <Stack width={'100%'} direction="row" justifyContent="space-between">
            <Card sx={{p: 2, m: 1.5, flexGrow: 1}}>
              <Typography fontSize="18px" fontWeight="700" m={2}>
                מס' תלמידים (מטופס הבקשה)
              </Typography>

              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <Stack
                  alignItems={'flex-start'}
                  justifyContent={'space-between'}
                >
                  <StudentsSummary
                    count={yeshivaGuyOffset?.studentCount || 0}
                    descriptionLineA="בחורים "
                    descriptionLineB=" בישיבה "
                  />
                  <Typography noWrap fontSize={'small'} color="red">
                    מתוכם {yeshivaGuyOffset?.offsetJoly} הושעו בגלל בג"צ
                  </Typography>
                </Stack>
                <Divider />

                <Stack alignItems={'center'} justifyContent={'stretch'}>
                  <StudentsSummary
                    count={fullDayOffset?.studentCount || 0}
                    descriptionLineA="אברכים בכולל"
                    descriptionLineB="יום שלם "
                  />
                  <Typography noWrap fontSize={'small'} color="red">
                    מתוכם {fullDayOffset?.offsetJoly || '0'} הושעו בגלל בג"צ
                  </Typography>
                </Stack>
                <Divider />

                <Stack alignItems={'center'} justifyContent={'space-between'}>
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <StudentsSummary
                      count={halfDayOffset?.shiftACount || '0'}
                      descriptionLineA="אברכים "
                      descriptionLineB=" בסדר א "
                    />
                    <StudentsSummary
                      count={halfDayOffset?.shiftBCount || '0'}
                      descriptionLineA="אברכים "
                      descriptionLineB="  בסדר ב "
                    />
                  </Stack>

                  <Typography noWrap fontSize={'small'} color="red">
                    מתוכם {halfDayOffset?.offsetJoly || '0'} הושעו בגלל בג"צ
                  </Typography>
                </Stack>
              </Stack>
            </Card>

            <Card sx={{p: 2, m: 1.5, width: '52%'}}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="18px" fontWeight="700" m={2}>
                  סטטוס תלמידים
                </Typography>
                <Select
                  sx={{
                    width: '122px',
                    height: '34px',
                    minWidth: 'fit-content',
                    fontSize: '14px',
                    fontWeight: '700',
                    backgroundColor:
                      'var(--transparent-grey-12, rgba(145, 158, 171, 0.12))'
                  }}
                  value={month ? JSON.stringify(month) : ''}
                  onChange={handleChangeMonth}
                >
                  {relevantMonths?.map((m, i) => (
                    <MenuItem
                      sx={{
                        color: 'black',
                        fontSize: '14px',
                        fontWeight: '700'
                      }}
                      value={JSON.stringify(m)}
                      key={i}
                    >
                      {`${m.name} ${m.year}`}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              {dashboard && (
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    <Icon style={{marginLeft: '8px'}} />
                    <Typography
                      fontSize="40px"
                      fontWeight="600"
                      color="#FFC107"
                    >
                      {dashboard?.allCount}
                    </Typography>
                    <Stack spacing={-1}>
                      <Typography fontSize="14px" fontWeight="700">
                        תלמידים
                      </Typography>
                      <Typography>במוסד</Typography>
                    </Stack>
                  </Stack>
                  <Divider />

                  <Stack direction="row" alignItems="center">
                    <Success style={{marginLeft: '8px'}} />
                    <Typography
                      fontSize="40px"
                      fontWeight="600"
                      color="success.main"
                    >
                      {dashboard?.payAllCount}
                    </Typography>
                    <Stack spacing={-1}>
                      <Typography fontSize="14px" fontWeight="700">
                        תלמידים
                      </Typography>
                      <Typography>מוצעים לתמיכה</Typography>
                    </Stack>
                  </Stack>
                  <Divider />

                  <Stack direction="row" alignItems="center">
                    <Info style={{marginLeft: '8px'}} />
                    <Typography
                      fontSize="40px"
                      fontWeight="600"
                      color="info.main"
                    >
                      {dashboard?.duplicateCount}
                    </Typography>
                    <Stack spacing={-1}>
                      <Typography fontSize="14px" fontWeight="700">
                        תלמידים
                      </Typography>
                      <Typography>כפולים</Typography>
                    </Stack>
                  </Stack>

                  <Divider />

                  <Stack direction="row" alignItems="center">
                    <Error style={{marginLeft: '8px'}} />
                    <Typography
                      fontSize="40px"
                      fontWeight="600"
                      color="error.main"
                    >
                      {dashboard?.rejectCount}
                    </Typography>
                    <Stack spacing={-1}>
                      <Typography fontSize="14px" fontWeight="700">
                        תלמידים
                      </Typography>
                      <Typography>נדחו</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Card>
          </Stack>

          <Stack
            direction="row"
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Stack direction="column" width={'100%'}>
              <Stack direction="row" justifyContent="space-between">
                <EditableCard
                  fieldWidth={'20%'}
                  width={'100%'}
                  fields={institutionDetails.map((d: any) => {
                    const value =
                      currentInstitution![d.key as keyof Institution] || ''
                    return {
                      key: d.key,
                      name: d.name,
                      value: value,
                      validationRegex: d.validationRegex
                        ? new RegExp(d.validationRegex)
                        : null
                    } as EditableCardField
                  })}
                  onSave={updateInstitution}
                  title={'פרטי מוסד'}
                  object={currentInstitution}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <EditableCard
                  fieldWidth={'30%'}
                  width={'60%'}
                  fields={associationDetails.map((d: any) => {
                    const value =
                      currentInstitution!.association![
                        d.key as keyof Association
                      ] || ''
                    return {
                      key: d.key,
                      name: d.name,
                      value: value,
                      validationRegex: d.validationRegex
                        ? new RegExp(d.validationRegex)
                        : null
                    } as EditableCardField
                  })}
                  onSave={updateAssociation}
                  title={'פרטי עמותה'}
                  object={currentInstitution?.association}
                />
                <EditableCard
                  fieldWidth={'45%'}
                  width={'40%'}
                  fields={bankDetails.map((d: any) => {
                    const value =
                      currentInstitution!.association![
                        d.key as keyof Association
                      ] || ''
                    return {
                      key: d.key,
                      name: d.name,
                      value: value,
                      validationRegex: d.validationRegex
                        ? new RegExp(d.validationRegex)
                        : null
                    } as EditableCardField
                  })}
                  onSave={updateAssociation}
                  title={'פרטי חשבון עמותה'}
                  object={currentInstitution?.association}
                  onCancel={onCancelBank}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{width: '100%'}}
                >
                  <Card sx={{width: '33%', p: 2, m: 1.5}}>
                    <Typography fontSize="18px" fontWeight="700" m={2}>
                      הערות מוסד
                    </Typography>
                    <Typography style={{whiteSpace: 'pre-line'}}>
                      {currentInstitution.externalNote}
                    </Typography>
                  </Card>
                  <Card sx={{width: '33%', p: 2, m: 1.5}}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{flexGrow: 1}}
                    >
                      <Typography fontSize="18px" fontWeight="700" m={2}>
                        הערות על הצלבות
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setIsEditMatchTeamNote(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                    <EditableText
                      text={currentInstitution.matchTeamNote}
                      isEditMode={isEditMatchTeamNote}
                      setIsEditMode={setIsEditMatchTeamNote}
                      onSave={updateMatchTeamNote}
                      placeholder="הכנס כאן את ההערות שלך..."
                      label="הערות על הצלבות"
                    />
                  </Card>
                  <Card sx={{width: '33%', p: 2, m: 1.5}}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{flexGrow: 1}}
                    >
                      <Typography fontSize="18px" fontWeight="700" m={2}>
                        הערות
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setIsEditNoteMode(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                    <EditableText
                      text={currentInstitution.internalNote}
                      isEditMode={isEditNoteMode}
                      setIsEditMode={setIsEditNoteMode}
                      onSave={updateNote}
                      placeholder="הכנס כאן את ההערות שלך..."
                      label="הערות "
                    />
                  </Card>
                </Stack>
              </Stack>
            </Stack>

            <EditableCard
              fieldWidth={'40%'}
              width={'30%'}
              fields={contactDetails.map((d: any) => {
                const value =
                  currentInstitution!.contact![d.key as keyof Contact] || ''
                return {
                  key: d.key,
                  name: d.name,
                  value: value,
                  fullWidth: d.fullWidth,
                  validationRegex: d.validationRegex
                    ? new RegExp(d.validationRegex)
                    : null
                } as EditableCardField
              })}
              onSave={updateContact}
              title={'פרטי מגיש בקשה'}
              object={currentInstitution?.contact}
            />
          </Stack>
        </Stack>
      )}
    </>
  )
})

interface StudentsSummaryProps {
  count: number
  descriptionLineA: string
  descriptionLineB: string
}
const StudentsSummary = ({
  count,
  descriptionLineA,
  descriptionLineB
}: StudentsSummaryProps) => {
  return (
    <Stack width={'100%'} direction="row" alignItems="center" spacing={3}>
      <Typography fontSize="40px" fontWeight="600">
        {count}
      </Typography>
      <Stack width={'100%'} alignItems={'flex-start'} spacing={-1}>
        <Typography noWrap fontSize="14px" fontWeight="700">
          {descriptionLineA}
        </Typography>
        <Typography noWrap>{descriptionLineB}</Typography>
      </Stack>
    </Stack>
  )
}
export default InstitutionsDetails
