import React, {useState, ChangeEvent, useEffect} from 'react'
import {Typography, TextField, Button, Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'

interface EditableTextProps {
  text: string | null | undefined
  isEditMode: boolean
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>
  onSave: (newText: string) => Promise<void> | void
  label?: string
  placeholder?: string
}

const EditableText = observer(
  ({
    text,
    isEditMode,
    setIsEditMode,
    onSave,
    label = 'הערות',
    placeholder = 'הכנס כאן את ההערות שלך...'
  }: EditableTextProps) => {
    const [currentText, setCurrentText] = useState<string>(text ?? '')

    useEffect(() => {
      setCurrentText(text || '')
    }, [text])

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentText(event.target.value)
    }

    const handleSave = async () => {
      if (currentText !== text) {
        await onSave(currentText)
      }
      setIsEditMode(false)
    }

    const handleCancel = () => {
      setCurrentText(text || '')
      setIsEditMode(false)
    }

    if (isEditMode) {
      return (
        <Stack>
          <TextField
            multiline
            rows={6}
            value={currentText}
            onChange={handleTextChange}
            fullWidth
            placeholder={placeholder}
            label={label}
          />
          <Stack padding={1} direction="row" justifyContent={'flex-end'}>
            <Button
              disabled={!currentText?.trim()}
              sx={{marginLeft: 1}}
              onClick={handleCancel}
              color="secondary"
              variant="outlined"
            >
              ביטול
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              שמירה
            </Button>
          </Stack>
        </Stack>
      )
    }

    return <Typography sx={{m: 2}}>{text || ''}</Typography>
  }
)
export default EditableText
