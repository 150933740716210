import {makeAutoObservable} from 'mobx'
import CodeObject from './CodeObject'
import Contact from './Contact'
import Association from './Association'
import Offset from './Offset'
import Payment from './payment'
import Audit from './Audit'

export default class Institution {
  id: number

  name: string

  typeId: number

  type?: CodeObject | null

  institutionHeadName?: string

  assignUserId?: number

  mail?: string

  statusId?: number

  status?: CodeObject | null

  contact?: Contact

  association?: Association

  offset?: Offset[]

  phone?: string

  institutionHeadPhone?: string

  learnLocation?: string

  city?: string

  houseNumber?: string

  zipCode?: string

  street?: string

  createdAt?: Date

  updatedAt?: Date

  internalNote?: string

  matchTeamNote?: string

  externalNote?: string

  isAssociationBelongTo?: boolean

  payments?: Payment[]

  approvePersonId?: number

  auditor?: string

  auditNote?: string

  audit?: Audit

  isUnhandleDuplicate?: boolean

  monthStatus?: number

  constructor(
    institution: any,
    statusesCodes?: CodeObject[],
    statusesTypes?: CodeObject[],
    studentsTypes?: CodeObject[]
  ) {
    this.id = institution.Id
    this.name = institution.Name
    this.typeId = institution.TypeId
    this.institutionHeadName = institution.InstitutionHeadName
    this.assignUserId = institution.AssignUserId
    this.mail = institution.Mail
    this.phone = institution.Phone
    this.statusId = institution.StatusId
    this.institutionHeadPhone = institution.institutionHeadPhone
    this.learnLocation = institution.LearnLocation
    this.city = institution.City
    this.houseNumber = institution.HouseNumber
    this.zipCode = institution.ZipCode
    this.street = institution.Street
    this.createdAt = new Date(institution.CreatedAt)
    this.updatedAt = new Date(institution.UpdatedAt) || undefined
    this.internalNote = institution.InternalNote
    this.externalNote = institution.ExternalNote
    if (statusesCodes)
      this.status = this.findStatus(statusesCodes, institution.StatusId)
    if (statusesTypes)
      this.type = this.findStatus(statusesTypes, institution.TypeId)
    this.contact = institution.Contact
      ? new Contact(institution.Contact)
      : undefined
    this.association = institution.Association
      ? new Association(institution.Association)
      : undefined
    this.isAssociationBelongTo = institution.IsAssociationBelongTo
    if (studentsTypes)
      this.offset = institution.InstitutionOffsets.map(
        (o: any) => new Offset(o, studentsTypes)
      )
    if (institution.Payments !== undefined && institution.Payments.length > 0) {
      this.payments = institution.Payments.map((o: any) => new Payment(o))
    }
    this.approvePersonId = institution.ApprovePersonId

    this.auditor = institution.Auditor

    this.auditNote = institution.AuditNote

    this.audit = new Audit({InstitutionId: institution.Id})

    this.isUnhandleDuplicate = institution.IsUnhandleDuplicate

    this.monthStatus = institution.MonthStatus
    this.matchTeamNote = institution.MatchTeamNote
    makeAutoObservable(this)
  }

  private findStatus(
    statuses: CodeObject[],
    statusId?: number
  ): CodeObject | null {
    if (statusId === undefined || statusId === null) {
      return null
    }
    return statuses.find(status => status.id === statusId) || null
  }
}
